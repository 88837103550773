import React from "react"
import styles from "./styles.module.less"

const Intro = ({ header, children }) => {
  return (
    <div className={styles.Intro}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>{header}</h1>
        </div>
        <div className={styles.paragraph}>{children}</div>

        {/* <button className={styles.button} onClick="">
          <img src={arrow} alt="" />
        </button> */}
      </div>
    </div>
  )
}

export default Intro
