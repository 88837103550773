import React from "react"

import Converse from "/components/Holiday2020/Converse"
import SEO from "/components/seo"

const ConverseHoliday2020 = () => (
  <>
    <SEO
      title="Thesis | Holiday 2020: Converse"
      description="Happy holidays from Thesis, a full-service digital agency in Portland, OR where strategy, creative, tech, and partner services combine to humanize the digital relationship between brands and people. Using data as the driver, our versatile work has the range to meet people where they are and the relevance to engage people as they are."
    />
    <Converse />
  </>
)

export default ConverseHoliday2020
