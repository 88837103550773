import React, { useState } from "react"
import styles from "./styles.module.less"

const MadLib = () => {
  return (
    <div className={styles.MadLibContainer}>
      <div className={styles.MadLib}>
        <div className={styles.Head}>
          <Sparkle className={styles.sparkle} />
          <Sparkle className={styles.sparkle} />
          <h2>Business-Speak Blitz</h2>
          <Sparkle className={styles.sparkle} />
          <Sparkle className={styles.sparkle} />
        </div>
        <div className={styles.Intro}>
          Ask a friend or relative to provide the missing words in this email
          without looking at the rest of it. Fill in the blanks, and — voila! —
          you are a marketer.
        </div>
        <div className={styles.Lib}>
          <Blank>[Salutation]</Blank> team, as per my last{" "}
          <Blank>[noun],</Blank>
          I'm circling back on the <Blank>[noun]</Blank> strategy for the Q4
          holiday email <Blank>[type of event]</Blank>. These will be typical{" "}
          <Blank>[adjective]</Blank> marketing emails, so there’s no need to
          reinvent the <Blank>[noun]</Blank> here. But I would like us to{" "}
          <Blank>[verb]</Blank> outside the <Blank>[noun]</Blank> a little.
          Let’s hop on a <Blank>[noun]</Blank>
          Tuesday so we can get our <Blank>[plural animals]</Blank> in a row. I
          do have a<Blank>[adjective]</Blank> stop at 11, and then I'll be
          working out of <Blank>[noun]</Blank> for the rest of the{" "}
          <Blank>[period of time]</Blank>. Going forward, I think we should
          touch
          <Blank>[noun]</Blank> every Thursday, until we really get the{" "}
          <Blank>[round object]</Blank> rolling.
          <Blank>[Color]</Blank>-sky scenario, we’ll have everything wrapped{" "}
          <Blank>[adverb]</Blank> in a bow by the 5th.
        </div>
      </div>
      <div className={styles.Scroll}>
        <div className={styles.Top}></div>
        <div className={styles.Bottom}></div>
      </div>
    </div>
  )
}

export default MadLib

const Blank = ({ children }) => {
  const [dirty, setDirty] = useState(false)
  let content = !dirty ? children : ""
  const handleClick = e => {
    setDirty(true)
  }
  return (
    <span className={styles.Blank} contentEditable onClick={handleClick}>
      {content}
    </span>
  )
}

const Sparkle = ({ className }) => {
  return (
    <svg
      width={22}
      height={24}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.6212 23.1627C11.2991 13.6681 18.3896 11.2944 21.85 11.2944C13.2059 11.2944 10.7624 3.80615 10.6212 0.0620117C10.4517 8.8784 3.62959 11.2238 0.239746 11.2944C8.54487 12.1422 10.6212 19.5598 10.6212 23.1627Z"
        fill="#D34C26"
        stroke="black"
        strokeWidth="1.49036"
      />
    </svg>
  )
}
